import React, { useContext } from 'react';
import { AppContext } from '../contexts/app-context';
import { Box, Container } from 'theme-ui';
import BlockTitle from 'components/block-title';
import Accordion from 'components/accordion/accordion';

const accordionData = [
  {
    isExpanded: false,
    title: "Who owns the generated copy?",
    titleEs: "¿A quién pertenece el copy generado?",
    contents: (
      <div>
        The ownership of the content generated in your account belongs to
        you. You have complete control over it and can use it for personal
        as well as commercial purposes. Writelytic has no ownership rights
        over the content in your account.
      </div>
    ),
    contentsEs: (
      <div>
        La propiedad del contenido generado en tu cuenta te pertenece. Tienes
        el control total sobre él y puedes utilizarlo tanto para fines
        personales como comerciales. Writelytic no tiene derecho de propiedad
        sobre el contenido presente en tu cuenta.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: "Which languages can be used with Writelytic?",
    titleEs: "¿Qué idiomas pueden utilizarse con Writelytic?",
    contents: (
      <div>
        Our chat can produce content in 50+ languages.
        Our writing tools can produce content in English and Spanish.
        Additionally, our user interface is accessible in both English and Spanish.
      </div>
    ),
    contentsEs: (
      <div>
        Nuestro chat puede producir contenido en más de 50 idiomas.
        Nuestras herramientas de escritura pueden producir contenidos en español e inglés.
        Además, nuestra interfaz de usuario es accesible tanto en español como en inglés.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: "Can I have more than 1 brand voice?",
    titleEs: "¿Puedo tener más de 1 voz de marca?",
    contents: (
      <div>
        Your plan includes 1 brand voice.
        You can add extra brand voices so you can cover different styles within your
        brand or different clients you have.<br/>
        If your subscription is yearly, each additional brand voice is $19/month.<br/>
        If your subscription is monthly, each additional brand voice is $25/month.
      </div>
    ),
    contentsEs: (
      <div>
        Tu plan incluye 1 voz de marca.
        Puedes añadir voces de marca adicionales para cubrir diferentes estilos
        dentro de tu marca o diferentes clientes que tengas.<br/>
        Si tu suscripción es anual, cada voz de marca adicional es $19/mes.<br/>
        Si tu suscripción es mensual, cada voz de marca adicional es $25/mes.<br/>
      </div>
    ),
  },
  {
    isExpanded: false,
    title: "What if I need to generate more words?",
    titleEs: "¿Qué pasa si necesito generar más palabras?",
    contents: (
      <div>
        If in any month you happen to need to generate more than 20,000 words, you can add another 20,000 words for the rest of that month for $20. Each month, your word spending is reset to 0. For example, if you started your subscription on the 13th, this would happen on the 13th of each month.
      </div>
    ),
    contentsEs: (
      <div>
        Si en algún mes necesitas generar más de 20,000 palabras, puedes añadir otras 20,000 palabras para el resto de ese mes por $20. Cada mes, tu gasto de palabras se reinicia a 0. Por ejemplo, si comenzaste tu suscripción un 13, esto ocurriría el día 13 de cada mes.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Do I have to sign a long-term contract?',
    titleEs: '¿Tengo que firmar un contrato de larga duración?',
    contents: (
      <div>
        No, you have the flexibility to cancel your monthly or yearly
        plan renewal whenever you wish.
      </div>
    ),
    contentsEs: (
      <div>
        No, tienes la flexibilidad de cancelar la renovación de tu plan
        mensual o anual cuando lo desees.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Can I upgrade from a monthly plan to a yearly one?',
    titleEs: '¿Puedo cambiar de un plan mensual a uno anual?',
    contents: (
      <div>
        You can upgrade to a yearly plan at any time, and the change will
        be applied starting with your next payment cycle. By opting for
        the yearly plan, you're getting 2 months free.
      </div>
    ),
    contentsEs: (
      <div>
        Puedes cambiar a un plan anual en cualquier momento, y el cambio se
        aplicará a partir del siguiente ciclo de pago. Al optar por el
        plan anual, obtienes 2 meses gratis.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Can I downgrade from a yearly plan to a monthly one?',
    titleEs: '¿Puedo cambiar de un plan anual a uno mensual?',
    contents: (
      <div>
        You can downgrade to a monthly plan at any time, and the change will
        be applied starting with your next payment cycle. Remember that when
        you are on the yearly plan, you're getting 2 months free.
      </div>
    ),
    contentsEs: (
      <div>
        Puedes cambiar a un plan mensual en cualquier momento, y el cambio se
        aplicará a partir del siguiente ciclo de pago. Recuerda que cuando
        estás en el plan anual, obtienes 2 meses gratis.
      </div>
    ),
  },
  {
    isExpanded: false,
    title: 'Who can use Writelytic?',
    titleEs: '¿Quién puede utilizar Writelytic?',
    contents: (
      <div>
        Writelytic is for anyone interested in content creation, including but not limited to marketers, entrepreneurs, agencies, and students. You do not need to be a professional writer or possess writing experience to make use of the platform.
      </div>
    ),
    contentsEs: (
      <div>
        Writelytic es para cualquiera que esté interesado en la creación de contenido, incluyendo pero no limitado a mercadólogos, emprendedores, agencias y estudiantes. No necesitas ser un escritor profesional o poseer experiencia en escritura para hacer uso de la plataforma.
      </div>
    ),
  },
];

const FAQ = () => {
  const { language, digistore24 } = useContext(AppContext);
  const es = language == "es";

  return (
    <Box as="section" id="faq" sx={styles.faqs}>
      <Container>
        <BlockTitle
          title={es ? "Preguntas Frecuentes" : "Frequently Asked Question"}
        />
        <Box sx={styles.faqWrapper}>
          <Accordion items={accordionData}/>
          { digistore24 &&
          <Box sx={styles.refundPolicy}>
          { es ?
          <>Estás respaldado por una política de devolución de 60 días</>
          :
          <>You're backed by a 60 days refund policy</>
          }
          </Box>
          }
        </Box>
      </Container>
    </Box>
  );
};

export default FAQ;

const styles = {
  faqs: {
    py: ['80px', null, null, null, '80px', '100px', '140px'],
    pb: ['50px', null, null, null, '110px', null, '140px'],
  },
  faqWrapper: {
    width: ['100%', null, null, '650px', '745px'],
    mx: 'auto',
  },
  refundPolicy: {
    textAlign: "center",
    px: ['15px', null, null, '30px'],
  }
};
