import React, { useContext } from 'react';
import { AppContext } from '../contexts/app-context';
import { Box, Container, Flex, Heading, Image } from 'theme-ui';

import brandVoiceImg from 'assets/images/brandvoice.png';

const BrandVoice = () => {
  const { language } = useContext(AppContext);
  const es = language == "es";

  return (
    <Box as="section" id="brandvoice" sx={styles.brandVoice}>
      <Container>
        <Flex sx={styles.flex}>
          <Box sx={styles.images}>
            <Image src={brandVoiceImg} />
          </Box>
          <Box sx={styles.content}>
            <span>
              {es ? "Voz de Marca" : "Brand Voice"}
            </span>
            <Heading as="h3">
              {es ?
              "Dale a tu marca la voz que se merece"
              :
              "Give your brand the voice it deserves"
              }
            </Heading>
            <p>
              {es ?
              "Tu marca es única y tiene una personalidad que la diferencia del resto. Writelytic puede aprender tu tono y estilo, de modo que cada contenido generado suene como tú (o alguien más a quien quieras sonar). Imagina el impacto de tener mensajes que capten perfectamente la esencia de tu marca, en todo momento."
              :
              "Your brand is unique and has a personality that sets it apart from the rest. Writelytic can learn your tone and style, ensuring that every piece of content generated sounds like you (or someone else you want to sound like). Imagine the impact of having messaging that perfectly captures the essence of your brand, every single time."
              }
            </p>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default BrandVoice;

const styles = {
  brandVoice: {
    mt: ['-90px', null, null, null, '0'],
    py: ['50px', null, null, null, '110px', null, '140px'],
  },
  flex: {
    flexWrap: 'wrap',
  },
  content: {
    flex: ['0 0 100%', null, null, null, '0 0 50%'],
    textAlign: ['center', null, null, null, 'left'],
    pt: ['80px', null, null, null, '0'],
    maxWidth: ['100%', null, null, '80%', '100%'],
    mx: ['auto', null, null, null, '0'],
    mb: ['30px', null, null, null, '0'],
    "&>span": {
      fontSize: '18px',
      fontWeight: 700,
      color: 'primary',
      display: 'block',
      lineHeight: 1,
    },
    h3: {
      color: '#0F2137',
      fontWeight: 700,
      fontSize: ['23px', null, null, null, '30px', '36px', '44px'],
      maxWidth: ['100%', null, null, null, null, '90%', '100%'],
      lineHeight: 1.36,
      letterSpacing: '-1.5px',
      mt: '20px',
      mb: '30px',
    },
    p: {
      color: '#02073E',
      fontSize: ['16px', null, null, '18px'],
      lineHeight: ['2', null, null, 2.33],
      mb: '30px',
    },
  },
  images: {
    flex: ['0 0 100%', null, null, null, '0 0 50%'],
  },
  chip: {
    backgroundColor: "#c9c9c9",
    p: "0.5rem",
    borderRadius: "6rem",
    display: "inline-block",
  },
};
