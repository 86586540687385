import React, { useContext } from 'react';
import { AppContext } from '../contexts/app-context';
import { Box, Button, Container, Image, Heading, Text } from 'theme-ui';
import { NavLink } from '../components/link';
import { keyframes } from '@emotion/core';
import bannerIcon1 from 'assets/banner-icon-1-1.svg';
import bannerIcon2 from 'assets/banner-icon-1-2.svg';
import bannerIcon3 from 'assets/banner-icon-1-3.svg';
import bannerIcon4 from 'assets/banner-icon-1-4.svg';
import bannerIcon5 from 'assets/banner-icon-1-5.svg';
import bannerIcon6 from 'assets/banner-icon-1-6.svg';
import bannerIcon7 from 'assets/dot-pattern.svg';
// import { Typewriter } from 'react-simple-typewriter';

//const twWords = ["social media content", "product descriptions", "marketing copy"];//"Engaging Content"];
//const twWordsEs = ["contenido para redes sociales", "descripciones de producto", "copy de marketing"];//"Contenido Atractivo"];
/* Old headline code
{ es ? "La forma más efectiva de crear" : "The most effective way to create"}
&nbsp;
<Typewriter
  words={es ? twWordsEs : twWords}
  cursor
  loop={0}/>
*/

const Banner = () => {
  const { language } = useContext(AppContext);
  const es = language == "es";

  return (
    <Box as="section" id="banner" sx={styles.banner}>
      <Container sx={styles.container}>
        <Image
          sx={styles.bannerIcon1}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon1}
        />
        <Image
          sx={styles.bannerIcon2}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon2}
        />
        <Image
          sx={styles.bannerIcon3}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon3}
        />
        <Image
          sx={styles.bannerIcon4}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon4}
        />
        <Image
          sx={styles.bannerIcon5}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon5}
        />
        <Image
          sx={styles.bannerIcon6}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon6}
        />
        <Image
          sx={styles.bannerIcon7}
          className="bannerIcon"
          alt="banner icon"
          src={bannerIcon7}
        />

        <Heading as="h2">
          {es ?
          //"La forma no genérica de escribir utilizando IA"
          "Deja de perder tiempo creando prompts, empieza a escribir más rápido"
          :
          //"The non-generic way to write using AI"
          "Stop wasting time prompt engineering, start writing faster"
          }
        </Heading>
        <Text as="p" sx={styles.text}>
          { es ?
          /*<>
          Deja atrás la escritura genérica de IA. Writelytic ayuda a que tu negocio se destaque de una manera única con contenido que está consistentemente en la marca y en el tono.
          </>*/
          <>
          No pierdas tiempo en perfeccionar el trabajo. Deja que Writelytic haga el trabajo por ti.
          </>
          :
          /*<>
          Leave generic AI writing behind. Writelytic helps your business stand out in a unique way with content that is consistently on-brand and on-tone.
          </>*/
          <>
          Don't waste time for perfecting the work. Let Writelytic do the work for you.
          </>
          }
        </Text>
        <NavLink
          path="pricing"
          label={
            <Button variant="primary" sx={styles.button}>
              { es ? "Comenzar" : "Get Started"}
            </Button>
          }
        />
      </Container>
    </Box>
  );
};

export default Banner;

const bannerAnim1 = keyframes`
    0% {
        transform: rotate3d(0, 1, 0, 0deg);
    }

    30% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    60% {
        transform: rotate3d(1, 0, 0, 0deg);
    }

    80% {
        transform: rotate3d(0, 0, 1, 5deg);
    }

    100% {
        transform: rotate3d(0, 1, 0, 0deg);
    }
`;

const bannerAnim2 = keyframes`
    0% {
        transform: translateY(0px) translateX(0) rotate(0);
    }

    30% {
        transform: translateY(30px) translateX(30px) rotate(15deg);
        transform-origin: center center;
    }

    50% {
        transform: translateY(50px) translateX(50px) rotate(45deg);
        transform-origin: right bottom;
    }

    80% {
        transform: translateY(30px) translateX(30px) rotate(15deg);
        transform-origin: left top;
    }

    100% {
        transform: translateY(0px) translateX(0) rotate(0);
        transform-origin: center center;
    }
`;

const bannerAnim3 = keyframes`
    0%,
    100% {
        transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
    }

    50% {
        transform: perspective(400px) rotate(-45deg) translateZ(20px) translateY(20px) translateX(20px);
    }
`;

const styles = {
  banner: {
    overflow: 'hidden',
    backgroundColor: '#F9FBFD',
    textAlign: 'center',
    pt: ['110px', null, null, null, '130px'],
    h2: {
      fontSize: ['28px', null, null, '32px', '38px', '48px', '64px'],
      lineHeight: 1.25,
      color: '#02073E',
      fontWeight: 700,
      width: '100%',
      maxWidth: ['100%', null, null, '55%', '500px', '640px', '851px'],
      mx: 'auto',
      mt: '30px',
      mb: '10px',
    },
  },
  text: {
    fontSize: ['14px', '14px', '14px', '16px', '16px', '18px'],
    lineHeight: [1.85, 1.85, 1.85, 1.85, 1.85, 2.33],
    color: 'textSecondary',
    mt: ['14px', '19px'],
    width: ['100%', '90%', '535px', null, '57%', '60%', '68%', '60%'],
    mx: 'auto',
    textAlign: 'center',
  },
  button: {
    display: 'block',
    mt: 25,
    mb: ['20px', null, null, '45px', '75px'],
    mx: 'auto',
  },
  container: {
    position: 'relative',
    '.bannerIcon': {
      position: 'absolute',
      display: ['none', null, null, null, 'block'],
    },
  },
  bannerIcon1: {
    top: '10%',
    left: '10%',
    animation: `${bannerAnim2} 8s linear infinite`,
  },
  bannerIcon2: {
    top: '10%',
    right: '10%',
    animation: `${bannerAnim2} 8s linear infinite`,
  },
  bannerIcon3: {
    bottom: '-90px',
    right: '-120px',
    animation: `${bannerAnim1} 5s ease-out infinite`,
  },
  bannerIcon4: {
    bottom: '0px',
    left: '-120px',
    animation: `${bannerAnim1} 5s ease-out infinite`,
  },
  bannerIcon5: {
    bottom: '50%',
    left: '15%',
  },
  bannerIcon6: {
    bottom: '-65px',
    left: '0px',
    animation: `${bannerAnim3} 9s linear infinite`,
  },
  bannerIcon7: {
    bottom: '30%',
    right: '0%',
  },
};
