/** @jsx jsx */
import { useContext } from 'react';
import { AppContext } from '../contexts/app-context';
import { jsx } from 'theme-ui';
import { Container, Grid, Box, Heading, Text } from 'theme-ui';
import BlockTitle from 'components/block-title';

import PatternBG from 'assets/patternBG.png';
import ArrowOdd from 'assets/arrowOdd.svg';
import ArrowEven from 'assets/arrowEven.svg';

const data = [
  {
    id: 1,
    title: "Select a writing tool",
    titleEs: "Selecciona una herramienta de escritura",
    text:
    //  'Select from a vast collection of writing tools designed to amplify your content impact and see real results.',
      'Select from a collection of writing tools designed to amplify your content impact and see real results.',
    textEs:
    //  'Selecciona entre una amplia colección de herramientas de escritura diseñadas para amplificar el impacto de tu contenido y ver resultados reales.',
      'Selecciona entre una colección de herramientas de escritura diseñadas para amplificar el impacto de tu contenido y ver resultados reales.',
  },
  {
    id: 2,
    title: 'Provide some context to Writelytic',
    titleEs: 'Proporciona algo de contexto a Writelytic',
    text:
      'Fill the requested fields to create a tailored and compelling masterpiece. Here, you can enter a tone or select one of your brand voices.',
    textEs:
      'Rellena los campos solicitados para crear una obra maestra personalizada y convincente. Aquí, puedes ingresar un tono o seleccionar una de tus voces de marca.',
  },
  {
    id: 3,
    //title: 'Spot the golden nuggets in the results',
    title: 'Get the results',
    //titleEs: 'Encuentra las perlas preciosas en los resultados',
    titleEs: 'Obtén los resultados',
    text:
      'Explore the results and edit, save or generate more like a specific one, ensuring that you achieve the perfect outcome for your objectives.',
    textEs:
      'Explora los resultados y edita, guarda o genera más como uno en particular, asegurándote de que consigues el resultado perfecto para tus objetivos.',
  }
];

export default function WorkFlow() {
  const { language } = useContext(AppContext);
  const es = language == "es";

  return (
    <section sx={styles.workflow} id="howitworks">
      <Container>
        <BlockTitle
          title={es ? "Cómo funcionan las herramientas de escritura" : "How writing tools work"}
          text={es ? "Empieza a crear en 3 sencillos pasos" : "Start creating in 3 easy steps"}
          isWhite={true}
        />

        <Grid sx={styles.grid}>
          {data.map((item) => (
            <Box sx={styles.card} key={item.id}>
              <Box sx={styles.iconBox}>{`0${item.id}`}</Box>
              <Box sx={styles.wrapper}>
                <Heading sx={styles.wrapper.title}>{es ? item.titleEs || item.title : item.title}</Heading>
                <Text sx={styles.wrapper.subTitle}>{es ? item.textEs || item.text : item.text}</Text>
              </Box>
            </Box>
          ))}
        </Grid>
      </Container>
    </section>
  );
}

const styles = {
  workflow: {
    backgroundColor: 'primary',
    backgroundImage: `url(${PatternBG})`,
    backgroundRepeat: `no-repeat`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'relative',
    py: ['65px', '80px', '100px', null, '120px'],
    pt: ['80px', null, null, null, '80px', '100px', '140px'],
  },
  grid: {
    mb: -1,
    pt: 0,
    gridGap: [
      '35px 0',
      null,
      '45px 30px',
      null,
      '50px 25px',
      null,
      null,
      '50px 65px',
    ],
    gridTemplateColumns: [
      'repeat(1,1fr)',
      null,
      'repeat(2,1fr)',
      'repeat(3,1fr)',
    ],
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    textAlign: ['center', null, 'left'],
    width: ['100%', '80%', '100%'],
    mx: ['auto'],
    px: [4, null, null, 0],
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 0,
      left: [0, null, null, null, null, 72, null, 90],
      backgroundRepeat: `no-repeat`,
      backgroundPosition: 'center center',
      width: 215,
      height: 60,
      '@media screen and (max-width:1220px)': {
        display: 'none',
      },
    },
    '&:nth-of-type(2n-1)::before': {
      backgroundImage: `url(${ArrowOdd})`,
    },
    '&:nth-of-type(2n)::before': {
      backgroundImage: `url(${ArrowEven})`,
      top: 17,
    },
    '&:last-child::before': {
      display: 'none',
    },
  },

  iconBox: {
    width: ['50px', null, '60px', null, null, '70px'],
    height: ['50px', null, '60px', null, null, '70px'],
    flexShrink: 0,
    borderRadius: [15, null, 23, null, null, 30],
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    mb: "25px",
    mx: ['auto', null, 0],
    fontSize: [6, null, 7, null, null, '30px'],
    fontWeight: 700,
    justifyContent: 'center',
    color: 'primary',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    mt: '-5px',
    title: {
      fontSize: [3, null, 4, null, null, 5],
      color: 'white',
      lineHeight: [1.4, null, null, null, null, 1.55],
      pr: [0, null, null, null, null, 2],
      mb: [2, 3],
    },

    subTitle: {
      fontSize: 1,
      fontWeight: 400,
      lineHeight: [1.85, null, null, 1.9, 2],
      color: 'white',
      opacity: 0.75,
      pr: [0, null, null, null, null, 5],
    },
  },
};
