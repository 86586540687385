import React from 'react';
import { AppContext } from '../contexts/app-context';
import { StickyProvider } from 'contexts/sticky/sticky.provider';
import SEO from 'components/seo';
import Layout from 'components/layout';
import Banner from 'sections/banner';
// import Benefits from 'sections/benefits';
// import Jackpot from 'sections/jackpot';
import WorkFlow from 'sections/workflow';
import Templates from 'sections/templates';
import Chat from '../sections/chat';
import BrandVoice from 'sections/brand-voice';
import Pricing from 'sections/pricing';
import FAQ from 'sections/faq';

import 'rc-drawer/assets/index.css';
import 'typeface-dm-sans';

export default function HomePage({appContextValue}) {
  return (
    <AppContext.Provider value={appContextValue}>
      <StickyProvider>
        <Layout>
          <SEO title="Writelytic" lang={appContextValue?.language}/>
          <Banner />
          <Chat />
          <BrandVoice />
          {/*<Benefits />*/}
          {/*<Jackpot />*/}
          <Templates />
          <WorkFlow />
          <Pricing />
          <FAQ />
          { appContextValue.digistore24 &&
          <script type="text/javascript" src="https://www.digistore24.com/trusted-badge/26348/Kp3UYxEs8EV828s/salespage"></script>
          }
        </Layout>
      </StickyProvider>
    </AppContext.Provider>
  );
}
