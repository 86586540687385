import React, { useContext, useState } from 'react';
import { AppContext } from '../contexts/app-context';
import { keyframes } from '@emotion/core';
import { Box, Container, Button } from 'theme-ui';
import BlockTitle from 'components/block-title';
import PriceCard from 'components/cards/price-card';
import { IoIosCheckmarkCircle } from "react-icons/io";

const pointsData = [
  {
    icon: <IoIosCheckmarkCircle />,
    text: '20,000 words/mo',
    textEs: '20,000 palabras/mes',
    isAvailable: true,
  },
  {
    icon: <IoIosCheckmarkCircle />,
    text: 'Unlimited number of chats',
    textEs: 'Número ilimitado de chats',
    isAvailable: true,
  },
  {
    icon: <IoIosCheckmarkCircle />,
    text: 'Prompt enhancer',
    textEs: 'Potenciador de prompts',
    isAvailable: true,
  },
  {
    icon: <IoIosCheckmarkCircle />,
    text: '1 Brand voice',
    textEs: '1 Voz de marca',
    isAvailable: true,
  },
  {
    icon: <IoIosCheckmarkCircle />,
    text: 'Unlimited projects',
    textEs: 'Proyectos ilimitado',
    isAvailable: true,
  },
];

const trialPointsData = [
  {
    icon: <IoIosCheckmarkCircle />,
    text: '2,000 words',
    textEs: '2,000 palabras',
    isAvailable: true,
  },
  {
    icon: <IoIosCheckmarkCircle />,
    text: 'Unlimited number of chats',
    textEs: 'Número ilimitado de chats',
    isAvailable: true,
  },
  {
    icon: <IoIosCheckmarkCircle />,
    text: 'Prompt enhancer',
    textEs: 'Potenciador de prompts',
    isAvailable: true,
  },
  {
    icon: <IoIosCheckmarkCircle />,
    text: '1 Brand voice',
    textEs: '1 Voz de marca',
    isAvailable: true,
  },
  {
    icon: <IoIosCheckmarkCircle />,
    text: 'Unlimited projects',
    textEs: 'Proyectos ilimitado',
    isAvailable: true,
  },
];

const PRICE_MONTHLY_DATA = [
  {
    priceText: <><span>Trial Period</span><br/>$3 for 3 Days</>,
    priceTextEs: <><span>Período de Prueba</span><br/>$3 por 3 Días </>,
    buttonText: 'Start Now',
    buttonTextEs: "Comienza Ahora",
    buttonLink: "https://www.digistore24.com/product/497626?plan=1026821",
    points: trialPointsData,
  },
  {
    buttonText: 'Start Now',
    buttonTextEs: "Comienza Ahora",
    buttonLink: "https://www.digistore24.com/product/497626?plan=1001887",
    priceWithUnit: '$35',
    points: pointsData,
  }
];

const PRICE_YEARLY_DATA = [
  {
    priceText: <><span>Trial Period</span><br/>$3 for 3 Days</>,
    priceTextEs: <><span>Período de Prueba</span><br/>$3 por 3 Días </>,
    buttonText: 'Start Now',
    buttonTextEs: "Comienza Ahora",
    buttonLink: "https://www.digistore24.com/product/497626?plan=1026824",
    points: trialPointsData,
  },
  {
    buttonText: 'Start Now',
    buttonTextEs: "Comienza Ahora",
    buttonLink: "https://www.digistore24.com/product/497626?plan=1001890",
    priceWithUnit: '$29',
    yearly: true,
    points: pointsData,
  }
];

const Pricing = () => {
  const { language } = useContext(AppContext);
  const es = language == "es";
  const [plan, setPlan] = useState({
    active: 'yearly',
    pricingPlan: PRICE_YEARLY_DATA,
  });

  const handlePlan = (plan) => {
    if (plan === 'monthly') {
      setPlan({
        ...plan,
        active: 'monthly',
        pricingPlan: PRICE_MONTHLY_DATA,
      });
    }
    if (plan === 'yearly') {
      setPlan({
        ...plan,
        active: 'yearly',
        pricingPlan: PRICE_YEARLY_DATA,
      });
    }
  };
  return (
    <Box as="section" id="pricing" sx={styles.pricing}>
      <Container>
        <BlockTitle
          title={es ? "Precio" : "Pricing"}
        />
        <Box sx={styles.btnWrap}>
          <Button
            onClick={() => handlePlan('monthly')}
            className={`${plan.active === 'monthly' ? 'active' : ''}`}
          >
            {es ? "Plan Mensual" : "Monthly Plan"}
          </Button>
          <Button
            onClick={() => handlePlan('yearly')}
            className={`${plan.active === 'yearly' ? 'active' : ''}`}
          >
            {es ? "Plan Anual" : "Yearly Plan"}
          </Button>
        </Box>
        <Box sx={styles.pricingWrapper}> 
        {plan.pricingPlan.map((price, index) => (
          <Box sx={styles.pricingCont}>
            <PriceCard data={price} key={`${plan.active}-card--key${index}`} />
          </Box>
        ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Pricing;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeIn2 = keyframes`
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const styles = {
  pricing: {
    pt: ['80px', null, null, null, '80px', '100px', '140px'],
    '.pricing__wrapper .package__card': {
      '.package__header': {
        animation: `${fadeIn} 0.8s ease-in`,
      },
      'ul > li': {
        animation: `${fadeIn2} 0.7s ease-in`,
      },
      button: {
        animation: `${fadeIn2} 1s ease-in`,
      },
    },
  },
  btnWrap: {
    width: '302px',
    height: '60px',
    mt: ['-20px', null, null, '0px'],
    backgroundColor: '#F7F8FB',
    borderRadius: '5px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    p: '7px',
    mx: 'auto',
    button: {
      backgroundColor: 'rgba(0,0,0,0)',
      borderRadius: '5px',
      color: '#0F2137',
      letterSpacing: '-0.24px',
      fontSize: '16px',
      fontWeight: 500,
      position: 'relative',
      outline: 'none',
      fontFamily: 'DM Sans',
      transition: 'all 500ms ease',
      '&.active': {
        boxShadow: '0px 3px 3.8px rgba(38, 78, 118, 0.1)',
        backgroundColor: 'primary',
        color: "#fff",
        cursor: "unset"
      },
      '&:hover.active': {
        backgroundColor: 'primary',
      },
      '&:hover': {
        backgroundColor: '#fff',
      }
    },
  },
  pricingWrapper: {
    textAlign: "center",
  },
  pricingCont: {
    maxWidth: '360px',
    display: "inline-block",
    px: "10px",
  },
};
