import { Box, Card, Text, Button } from 'theme-ui';
import React, { useContext } from 'react';
import { AppContext } from '../../contexts/app-context';
import List from '../list';

export default function PriceCard({
  data: {
    header,
    priceWithUnit,
    yearly,
    buttonText = 'Get Started!',
    buttonTextEs,
    buttonLink,
    anotherOption,
    points,
    priceText,
    priceTextEs,
  },
}) {
  const { language } = useContext(AppContext);
  const es = language == "es";

  const btnText = es ? buttonTextEs || buttonText : buttonText;
  const prcText = es ? priceTextEs || priceText : priceText;

  return (
    <Card
      className={header ? 'package__card active' : 'package__card'}
      sx={styles.pricingBox}
    >
      {header && <Text sx={styles.header}>{header}</Text>}
      <Box>
        <Box className="package__header" sx={styles.pricingHeader}>
          <Text className="package__price" sx={styles.price}>
            { prcText ?
            prcText
            :
            <>
            {priceWithUnit}
            <span>{es ? "/mes" : "/month"}</span>
            { yearly &&
            <span style={{display:"block"}}>{es ? "facturado anualmente" : "billed yearly"}</span>
            }
            </>
            }
          </Text>
        </Box>
        <List items={points} childStyle={styles.listItem} />
        <Box sx={styles.buttonGroup}>
          <a href={buttonLink}>
          <Button variant="primary" aria-label={btnText}>
            {btnText}
          </Button>
          </a>
          {anotherOption && (
            <Button
              variant="textButton"
              className="free__trail"
              aria-label={anotherOption}
            >
              {anotherOption}
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
}

const styles = {
  pricingBox: {
    borderRadius: 20,
    position: 'relative',
    transition: 'all 0.3s',
    p: ['35px 25px', null, null, '40px'],
    width: ['100%', '75%', '100%'],
    mt: '10px',
    mx: [0, 'auto', 0],
    '&:before': {
      position: 'absolute',
      content: "''",
      width: '100%',
      top: 0,
      left: 0,
      height: '100%',
      border: '1px solid rgba(38, 78, 118, 0.1)',
      borderRadius: 'inherit',
      transition: 'all 0.3s',
      zIndex: -1,
    },
    '&:hover': {
      boxShadow: '0px 4px 25px rgba(38, 78, 118, 0.1) !important',
      '&:before': {
        opacity: 0,
      },
    },
  },
  header: {
    height: ['28px', null, null, '32px'],
    backgroundColor: 'yellow',
    borderRadius: '5px',
    fontWeight: 'bold',
    fontSize: 1,
    lineHeight: 1.2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    position: 'absolute',
    top: '-17px',
    letterSpacing: '-.14px',
    px: '12px',
  },
  pricingHeader: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    mb: ['30px', null, null, null, '40px'],
    p: {
      fontSize: [1, 2],
      color: 'text',
      lineHeight: 'heading',
    },
    '.package__name': {
      marginBottom: [1, null, 2],
    },
  },
  price: {
    fontWeight: 500,
    fontSize: [4, null, 5, null, '30px'],
    lineHeight: 1,
    letterSpacing: '-0.55px',
    color: 'text',
    marginBottom: 2,
    textAlign: "center",
    '> span': {
      position: 'relative',
      pl: '3px',
      display: 'inline-block',
      fontSize: [1, 2],
      fontWeight: 'normal',
    },
  },
  listItem: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: [1, 2],
    lineHeight: [1.75, 1.6],
    mb: 3,
    alignItems: 'flex-start',
    color: 'text',
    '&.closed': {
      opacity: 0.55,
      button: {
        color: '#788FB5',
      },
    },
  },
  buttonGroup: {
    textAlign: 'center',
    mt: ['30px', null, null, null, '35px'],
    '.free__trail': {
      color: 'secondary',
      width: '100%',
      justifyContent: 'center',
      fontWeight: 700,
      fontSize: ['14px', 1],
      p: '20px 0 0',
    },
  },
};
